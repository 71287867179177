#root {
  height: 100vh;
}

.MuiFormLabel-root.Mui-focused {
  color: grey !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

.cover-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.text-border {
  letter-spacing: 0.4rem !important;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #239fce;
}

@media only screen and (max-width: 600px) {
  .navbar-logo {
    width: 35% !important;
  }

  .navbar-logo {
    width: 30% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .navbar-logo {
    width: 20% !important;
  }
}

.container {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;
}

.container>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-logo {
  height: 7%;
  width: 7%;
}

.tab-class {
  text-transform: capitalize !important;
  letter-spacing: 0 !important;
}

.cards-responsive {
  display: grid;
  grid-auto-columns: 100%;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.color-primary {
  color: #007CB4;
}

.card-header {
  position: relative;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-decoration {
  text-decoration: none !important;
}

.tab-font-icon-size {
  font-size: 1.5rem !important;
}

.card-one-header-color :after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 8px #02b497;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.card-two-header-color :after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 8px #2c70ff;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.card-three-header-color :after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 8px #ff6e1d;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.card-one-header-color {
  background-color: #02b497;
}

.card-two-header-color {
  background-color: #2c70ff;
}

.card-three-header-color {
  background-color: #ff6e1d;
}

.post-container {
  max-width: 100%;
  margin: 0 auto;
  /* padding: 0 2rem; */
}

.post-responsive-container {
  padding: 0;
}

.post-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-library-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.object-fit-covor {
  object-fit: cover;
}

.padding-3 {
  padding: 3px !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-10 {
  padding: 10px !important;
}

.we-are-live-post-card {
  padding: 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
}

.we-are-live-post-video-card {
  padding: 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  border-radius: 10px !important;
}

.we-live-border-bottom {
  border-bottom: 2px solid #007CB4;
}

.active-video-playing-card {
  width: 98% !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  border-radius: 50px !important;
  border: 10px solid #007CB4;
}

.border-bottom-video-card {
  border-bottom: 2px solid grey;
}

.preferred-partner-image-border {
  border-radius: 10px !important;
  border: 2px solid #0e3647;
  overflow: 'hidden';
  object-fit: cover;
}

.video-img {
  object-fit: contain;
  width: 140px;
  height: 105px;
}

.responsive-video-img {
  object-fit: contain;
  width: 120px;
  height: 95px;
}

.post-main-title {
  line-height: 18px !important;
  font-size: 16px !important;
}

.post-main-sub-title {
  line-height: 18px !important;
  font-size: 13px !important;
}

.object-fit-contain {
  object-fit: contain;
}

.border-0 {
  border: 0 !important;
}

/* div {
    border: 1px dotted black;
} */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-template-columns: repeat(3, 1fr); */
  grid-auto-rows: max-content;
  gap: 24px;
}

.grid-item {
  display: grid;
  /* grid-auto-rows: max-content; */
}

.grid-item-title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 15px;
}

.scroll {
  margin-top: 10px;
  width: auto;
  /* height: auto; */
  /* max-height: 200px;     */
  /* overflow: scroll; */
  /* height: calc(100% - 140px); */

  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px #007CB4;  */
  background: #007CB4;
}

.read-more-less-more,
.read-more-less-less {
  color: #007CB4;
}

.link a {
  color: #007CB4;
  text-decoration: none;
}

.link a:visited {
  color: #007CB4;
  text-decoration: none;
}

.refer-body {
  background-image: 'url(/static/images/background.jpg)';
  background-size: cover;
  text-align: center;
}

@font-face {
  font-family: 'hit-run';
  src: url('fonts/HitandRun-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'trocchi-regular';
  src: url('fonts/Trocchi-Regular.ttf') format('truetype');
}

.head-text {
  font-family: 'hit-run' !important;
  text-align: center;
  font-size: 33.5px;
  letter-spacing: 10px;
  color: #f9a048;
}

.head-text h1 {
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #239fce;
}

.head-text span {
  color: #003b53;
}

.box {
  width: 50%;
  min-height: 140px;
  border-radius: 20px;
  background-color: #fff9c7;
  margin: auto;
  margin-bottom: 20px;
}

.box h1 {
  font-family: sans-serif;
  font-size: 25px;
  color: #4DE0F3;
  font-weight: 600;
}

.box span {
  font-family: sans-serif;
  font-size: 23px;
  float: right;
  margin-right: 13%;
  font-weight: 300;
  color: #ffffff;
  margin-top: 10px;
}

.video {
  background-color: #f0f0f0;
  width: 500px;
  height: 335px;
  margin: auto;
  border-top: 3px solid black;
  border-right: 3px solid black;
  position: relative;
  margin-bottom: 30px;
}

.video .videoImage {
  width: 350px;
}

.video h1 {
  font-family: sans-serif;
  font-style: italic;
  font-size: 35px;
  color: #088ac7;
}

.buttons img {
  width: 250px;
  cursor: pointer;
  margin-bottom: 10px;
}

.buttons h1 {
  font-family: sans-serif;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  color: #4DE0F3;
  font-weight: bold;
  margin-bottom: 40px;
  width: 600px;
  line-height: 1.6;
  margin: auto;
}

.review {
  display: grid;
  width: 600px;
  margin-bottom: 20px;
  text-align: center;
}

.review img {
  width: 100px;
  margin-top: 0;
  float: left;
}

.review h2 {
  font-family: sans-serif;
  font-style: italic;
  color: #4DE0F3;
  font-size: 23px;
  text-align: start;
}

.review h2 div {
  text-align: end;
}

.review span {
  font-size: 21px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
}

.footer {
  width: 100%;
  height: 100px;
  background-image: 'url(/static/images/footer.PNG)';
}

@media only screen and (max-width: 882px) {
  .box {
    width: 84%;
    min-height: 100px;
  }

  .head-text h1 {
    font-size: 21px;
    text-align: center;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #239fce;
  }

  .box h1 {
    font-size: 18px;
    padding: 15px;
  }

  .box span {
    font-size: 16px;
  }

  .video .videoImage {
    width: 300px;
  }

  .video {
    width: 320px;
    height: 280px;
  }

  .buttons img {
    width: 140px;
  }

  .buttons h1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 350px;
    border-bottom: 20px;
  }

  .review img {
    width: 60px;
  }

  .review h2 {
    font-size: 14px;
  }

  .review span {
    font-size: 12px;
    margin-left: 30%;
  }

  .review {
    width: 350px;
  }

  .video h1 {
    font-size: 30px;
  }
}

:root {
  /*========== Colors ==========*/
  --first-color: #007CB4;
  --black-color: hsl(248, 24%, 10%);
  --white-color: #fff;
  --body-color: hsl(248, 100%, 99%);
  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  --small-font-size: 0.813rem;
}

/*=============== SIDEBAR ===============*/
.nav {
  width: 300px;
  height: max-content;
  position: fixed;
  inset: 0;
  margin: auto 0;
  z-index: 1000;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav::-webkit-scrollbar {
  display: none;
}

.nav__list {
  position: relative;
  background-image: url('/public/static/images/menu_bg.svg');
  background-size: cover;
  padding-block: 175px;
  border-radius: 0 51px 51px 0;
  display: grid;
  justify-content: center;
  row-gap: 2rem;
}

.nav__link {
  position: relative;
  display: inline-flex;
  padding: 4px;
  border-radius: 50%;
  transition: background 0.4s;
}

.nav__link i {
  font-size: 1.25rem;
  color: var(--white-color);
  transition: color 0.4s;
}

.nav__link i img {
  width: 32px;
}

.nav__link:hover .nav__name {
  opacity: 1;
  transform: translateX(48px);
}

.nav__name {
  position: absolute;
  top: 0;
  transform: translateX(56px);
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: var(--small-font-size);
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 3rem;
  pointer-events: none;
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
}

.nav__circle-1,
.nav__circle-2 {
  width: 80px;
  height: 80px;
  background-color: var(--body-color);

  border-radius: 50%;
  position: absolute;
  left: 0;
  z-index: 10;
}

.nav__circle-1 {
  top: -78px;
}

.nav__circle-2 {
  bottom: -78px;
}

.nav__square-1,
.nav__square-2 {
  width: 35px;
  height: 40px;
  background-color: #088ac7;
  position: absolute;
  left: 0;
  z-index: 1;
}

.nav__square-1 {
  top: -39px;
}

.nav__square-2 {
  bottom: -39px;
}

/* Active link */
.active-link {
  background-color: var(--white-color);
}

.active-link i {
  color: #088ac7;
}

.userBadgeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 30px;

  background: linear-gradient(180deg,
      rgb(174, 134, 37) 0%,
      rgb(247, 239, 138) 30%,
      rgb(210, 172, 71) 58%,
      rgb(237, 201, 103) 100%);

  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 142px;
  height: 40px;
  margin-bottom: 20px;
}

.userBadgeText {
  font-size: 20px;
  font-family: "Lato";
  color: rgb(0, 0, 0);
  font-weight: bold;
  line-height: 1.997;
}

.pageNameContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 460px;
}

.pageName {
  font-size: 33.333px;
  font-family: 'Lato';
  color: rgb(255, 255, 255);
  line-height: 1.2;
  padding: 5px 0px;
}

@media only screen and (max-width: 1377px) {}

@media only screen and (max-width: 992px) {
  .pageNameContainer {
    /*margin: 0 auto;
    width: 100%;
    background-color: #016eb6;
    background-image: none;
    justify-content: space-between;*/
    display: none;
  }

  .pageName {
    font-size: 20px;
    width: 60%;
  }
}

/* New UI Css */
.name-container {
  width: 25%;
  height: 40px;
  margin-left: 100px;
  margin-top: 80px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid rgb(8, 138, 198);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.overlay-container {
  position: absolute;
  margin-top: 14px;
  top: 13%;
  left: 49%;
  width: 15%;
  transform: translate(-50%, -50%);
  /* background-color: rgb(8, 138, 198); */
  background-color: black;
  box-shadow: 0 5px 5px 0 grey;
  padding: 10px;
  /* border-radius: 70px 70px 0 0; */
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

hr {
  height: 1px;
  /* margin: 10px 0; */
  background: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(0, 0, 0, 0)), color-stop(0.5, #333333), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  border: 0;
}

hr:after {
  display: block;
  content: '';
  height: 50px;
  background-image: -webkit-gradient(radial, 50% 0%, 0, 50% 0%, 116, color-stop(0%, #00CAF0), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-radial-gradient(center top, farthest-side, #00CAF0 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -moz-radial-gradient(center top, farthest-side, #00CAF0 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-radial-gradient(center top, farthest-side, #00CAF0 0%, rgba(255, 255, 255, 0) 100%);
  background-image: radial-gradient(farthest-side at center top, #00CAF0 0%, rgba(255, 255, 255, 0) 100%);
}


.app-container {
  height: 100vh;
  margin-top: 20vh;
}

.left-section,
.right-section {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

.right-section {
  background-image: url(../src/assets/Advertisement_bg.jpeg);
}

.middle-section {
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
}

.middle-column {
  padding: 8px;
  background-color: #e0e0e0;
}

.right-row {
  padding: 8px;
  background-color: #e0e0e0;
}

.right-section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.centered-content {
  display: flex;
  justify-content: center;
}


.heading {
  width: 125px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  background-color: rgb(8, 138, 198);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading2 {
  min-width: 125px;
  min-height: 30px;
  border-radius: 0px;
  text-align: center;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-right: 10px;
  padding-left: 10px;
}

.hover-div {
  padding: 10px;
  border: 2.5px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: row;
}

.hover-div-select {
  padding: 10px;
  border: 2.5px solid transparent;
  background-color: 'black';
  border-radius: '10px 10px 0 0';
  transition: border-color 0.3s;
  display: flex;
  flex-direction: row;
  color: white
}



@media (max-width: 900px) {
  .hover-div {
    width: 20px;
    height: 10px;
    padding-bottom: 30px;
    display: inline-block;
  }

  .hover-div Typography {
    text-align: center;
  }
}

/* .hover-div:hover {
  border-color: rgb(8, 138, 198);
  background-color: white;
} */

.image-container {
  position: relative;
  width: auto;
  height: 1050px;
}

.background-image {
  width: 100%;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.foreground-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foreground-image {
  width: auto;
  height: 400px;
  z-index: 1;
}

.overlay-image {
  position: absolute;
  width: 175px;
  height: 375px;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 20px;
  border: 6px solid white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 1);
}

@media (max-width: 900px),
(max-width: 600px) {
  .app-container {
    margin-top: 20px;
  }
}

@media (max-width: 900px) {
  .left-section Box {
    display: flex;
    flex-direction: column;
  }

  .left-section {
    margin-top: 0px;
  }
}

@media (max-width: 900px) {
  .Yacht {
    display: none;
  }
}

@media (max-width: 900px) {
  .hover-div {
    display: flex;
    flex-direction: column;
  }
}

.locked {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.avatar-container {
  position: relative;
  bottom: 55px;
  height: 0px;
}

.large-avatar-container {
  display: flex;
  justify-content: center;
}

.avatar {
  position: sticky;
  left: 6%;
  top: 30%;
  transform: translateX(-50%);
  z-index: 2;
}

#root {
  height: 100vh;
}

.chatMessage a {
  text-decoration: none;
  color: rgb(8, 138, 198);
}

.mediaDscription a {
  text-decoration: none;
  color: rgb(8, 138, 198);
}

.MuiTypography-root:first-letter {
  text-transform: uppercase;
}


.MuiTimePickerToolbar-root>.MuiGrid-root>.MuiButtonBase-root,
.MuiPickersToolbar-root>.MuiGrid-root>.MuiButtonBase-root {
  display: none;
}


.splide__arrow--prev,
.splide__arrow--next {
  background: #ffffff66 !important;
  color: #ffffff;
  opacity: 1 !important;
  height: 3em !important;
  width: 3em !important;
  /* Change this to the desired color */
}



.splide__arrow:disabled {
  opacity: 0.3 !important;
}

@font-face {
  font-family: 'Architects Daughter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTYlg4-7jA-U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.architects-daughter-regular {
  font-family: "Architects Daughter" !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.full-height-width {
  height: 100%;
  width: 100%;
}


.course-video::-webkit-media-controls-timeline {
  display: none !important;
}


/* .gjs-one-bg {
  background-color: red !important;
} */




.gjs-four-color-h:hover {
  color: rgb(8, 138, 198) !important;
}

.gjs-four-color {
  color: rgb(8, 138, 198) !important;
}

/* 
.gjs-two-color-h {
  border-color: red !important;
} */

.gjs-am-file-uploader {
  display: none !important;
}

.gjs-am-assets-cont {
  width: 100% !important;
}

.vimeo-video-contain iframe {
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}


::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEF1F7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #93989F;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #93989F;
}


.white-link a {
  color: #ffffff;
  text-decoration: underline;
}

.white-link a:visited {
  color: #ffffff;
  text-decoration: underline;
}